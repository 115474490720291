$dark: #000;

.add-facility-wrapper {
  .wrapper-days {
    margin-bottom: 15px;
  }

  .wrapper-header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;

    .green-button {
      margin: 0;
    }

    .btn-back {
      border-radius: 2rem;
    }

    .icon-back {
      color: #ffbe46;
      font-size: 40px;
    }

    .title {
      display: inline-block;
      margin-left: 10px;
      font-size: 25px;
      font-weight: 600;
    }

    .wrapper-active {
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;

      label {
        margin-bottom: 0;
      }
    }
  }

  .time-picker {
    width: 100%;
    border: 1px solid #4bbb46;
    border-radius: 5px;
    padding: 5px;
    margin-top: 1px;
  }

  .wrapper-images-set {
    width: 420px;
    margin-top: 20px;

    .upload-icon {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 95px;
      left: 185px;
      .fas {
        color: #8bcb89;
      }
    }
    .main-image-wrap {
      width: 420px;
      height: 220px;
      position: relative;
      background-color: lightgray;
      border-radius: 5px;
      overflow: hidden;
    }
    img.img-main {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .booking-profile {
    width: 30px;
    height: 30px;
  }

  .wrapper-bottom-set {
    width: 100%;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 9px;
    flex-direction: row;
    flex-wrap: wrap;

    .other-image-wrap {
      position: relative;

      .remove-button {
        position: absolute;
        background-color: transparent;
        color: white;
        top: 13px;
        right: 15px;
        border-radius: 20px;
        background: #d44242;
        width: 20px;
        height: 20px;
        border: none;
        font-size: 10px;
      }
    }

    .other-image-upload-icon {
      width: 75px;
      height: 70px;
      background: #efefef;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      border-radius: 8px;
      color: #8bcb89;
      font-size: 18px;
    }

    img {
      width: 75px;
      height: 70px;
      object-fit: cover;
      border-radius: 10px;
      margin-top: 10px;
    }
  }

  .wrapper-info {
    width: 500px;
    margin-top: 40px;

    .icon-info {
      font-size: 22px;
      color: #4bbb46;
      background-color: #c9eac7;
      padding: 6px;
      border-radius: 50%;
    }

    .title {
      display: inline-block;
      margin-left: 15px;
      font-size: 23px;
      font-weight: 600;
      color: #4bbb46;
    }

    .wrapper-form {
      margin-top: 20px;

      .inp {
        position: relative;
        margin: auto;
        width: 100%;
        border-radius: 3px;
        overflow: hidden;
        margin-bottom: 10px;

        .label {
          position: absolute;
          top: 17px;
          left: 12px;
          font-size: 14px;
          color: rgba($dark, 0.5);
          font-weight: 500;
          transform-origin: 0 0;
          transform: translate3d(0, 0, 0);
          transition: all 0.2s ease;
          pointer-events: none;
        }

        .focus-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($dark, 0.05);
          z-index: -1;
          transform: scaleX(0);
          transform-origin: left;
        }

        input {
          appearance: none;
          width: 100%;
          border: 0;
          font-family: inherit;
          padding: 16px 12px 0 12px;
          height: 55px;
          transition: all 0.15s ease;
          border: 1px solid #4bbb46;
          border-radius: 5px;
          width: 100%;

          &:hover {
            background: rgba($dark, 0.04);
          }

          &:not(:placeholder-shown) {
            + .label {
              color: rgba($dark, 0.5);
              transform: translate3d(0, -7px, 0) scale(0.75);
            }
          }

          &:focus {
            outline: none;

            + .label {
              color: #4bbb46;
              transform: translate3d(0, -7px, 0) scale(0.75);
              + .focus-bg {
                transform: scaleX(1);
                transition: all 0.1s ease;
              }
            }
          }
        }
      }
    }
  }

  .wrapper-title-availability {
    margin-bottom: 20px;

    .icon-clock {
      font-size: 22px;
      color: #4bbb46;
      background-color: #c9eac7;
      padding: 6px;
      border-radius: 50%;
    }

    .title {
      display: inline-block;
      margin-left: 15px;
      font-size: 23px;
      font-weight: 600;
      color: #4bbb46;
    }
  }

  .wrapper-availability {
    .wrapper-form {
      .label-radio {
        font-size: 15px;
        margin-right: 10px;
        margin-bottom: 20px;

        input {
          margin-right: 5px;
        }
      }

      .wrapper-days {
        .label-checkbox {
          font-size: 15px;
          margin-right: 10px;
          white-space: nowrap;

          input {
            margin-right: 5px;
          }
        }

        .availability-time-wrap {
          .btn-time-remove {
            padding: 0;
            background: none;
            color: red;
            border: none;
            position: absolute;
            top: 7px;
            left: -7px;
          }
        }

        .time {
          width: 75px;
          margin: 0 10px;
          border: 1px solid #4bbb46;
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          padding: 3px 10px;
        }

        .time:focus {
          outline: 0;
        }

        button.add-button {
          background-color: #40404d;
          border: 1px solid;
          color: #fff;
          border-radius: 5px;
          font-weight: bold;
          line-height: 28px;
          height: 35px;
          width: 35px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .wrapper-duration {
    .title {
      font-size: 13px;
    }

    input {
      width: 75px;
      margin: 10px 10px 20px 0px;
      border: 1px solid #4bbb46;
      border-radius: 4px;
      font-size: 14px;
      padding: 3px 10px;
    }

    .sub-title {
      display: inline-block;
      color: #4bbb46;
    }
  }

  .wrapper-title-associated-charges {
    margin-top: 30px;
    margin-bottom: 20px;

    .icon-clock {
      font-size: 22px;
      color: #4bbb46;
      background-color: #c9eac7;
      padding: 6px;
      border-radius: 50%;
    }

    .title {
      display: inline-block;
      margin-left: 15px;
      font-size: 23px;
      font-weight: 600;
      color: #4bbb46;
    }
  }

  .wrapper-associated-charges {
    .wrapper-form {
      .label-radio {
        font-size: 15px;
        margin-right: 10px;
        margin-bottom: 20px;

        input {
          margin-right: 5px;
        }
      }

      .facility-charges {
        display: flex;
        align-items: center;
        margin-right: 20px; /* Adjust spacing between Fixed and Hourly */
      }
      
      .hourly-charges {
        display: flex;
        align-items: center;
      }
      
      .wrapper-charges {
        display: flex;
        align-items: center;
        .label-checkbox {
          font-size: 15px;
          margin-right: 10px;
          width: 240px;

          input {
            margin-right: 5px;
          }
        }

        .input-charge {
          width: 100px;
          margin: 0 10px;
          border: 1px solid #4bbb46;
          border-radius: 4px;
          font-size: 14px;
          padding: 3px 10px;
        }

        .input-charge:focus {
          outline: 0;
        }

        .sub-title {
          display: inline-block;
          color: #4bbb46;
          font-weight: 600;
        }
      }
    }
  }

  .booking-wrap {
    background-color: #f9fcff;
    margin-top: 40px;
    padding: 20px;
    border-radius: 10px;

    .title {
      display: inline-block;
      margin-left: 15px;
      font-size: 23px;
      font-weight: 600;
      color: #4bbb46;
    }
  }
}
.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}
.form-control{
  border-color: #4bbb46 !important;
}


